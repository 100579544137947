<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.serial_num" placeholder="根据订单编号进行查询" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker style="width: 100%" show-time @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model="queryParam.invoice_num" class="full-width" />
            </a-form-item>
          </a-col>
          <!-- <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                allowClear
                v-model="queryParam.order_type"
                @change="handleBizTypeChange"
              >
                <a-select-option :key="2">暂落箱业务</a-select-option>
                <a-select-option :key="3">仓库租赁业务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款方式">
              <a-select v-model="queryParam['charge_type']" allowClear style="width: 100%">
                <a-select-option v-for="o in chargeType" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <!-- <span slot="invoice_info" slot-scope="record">
        <p>委托单位：{{ record.customer_name }}</p>
        <p>纳税人识别号：{{ record.tax_no }}</p>
        <p>地址：{{ record.address }}</p>
        <p>电话：{{ record.mobile }}</p>
        <p>开户银行：{{ record.bank }}</p>
        <p>银行卡号：{{ record.bank_card_no }}</p>
        <p>备注：{{ record.remark }}</p>
      </span> -->
      <div slot="orderNumList" slot-scope="text">
        <span v-for="item in text" :key="item" style="display:block">{{ item }}</span>
      </div>
      <div slot="feeInfoList" slot-scope="fee">
        <div v-for="item in fee" :key="item.id">
          <!-- <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag> -->
          <span v-if="item.charge_type === 1">
            {{ item.fee_name }}
            <a-tag color="#FF0000" style="margin-bottom:2px;">
              现金：{{ item.amount }}
            </a-tag>
          </span>
          <span v-else-if="item.charge_type === 2">
            {{ item.fee_name }}
            <a-tag color="#1E90FF" style="margin-bottom:2px;">
              月结：{{ item.amount }}
            </a-tag>
          </span>
        </div>
      </div>
      <div slot="invoiceRecordList" slot-scope="text">
        <!-- <span v-for="item in text" :key="item.id" style="display:block">{{ item.invoice_no }} 金额：{{ item.tax_price_total }} 税额：{{ item.tax_total }}</span> -->
        <div v-for="item in text" :key="item.id">
          <span v-if="item.invoice_type_code==='026'||item.invoice_type_code==='028'||item.invoice_type_code==='01'||item.invoice_type_code==='02'">
            <a v-if="item.void_status === 0 && item.is_download === '1'" style="color: red" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            <span v-if="item.void_status === 1">{{ item.invoice_no }}<a-tag color="red">失效</a-tag></span>
            <a v-if="item.void_status === 0 && item.is_download === '0'" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
          </span>
          <span v-else>{{ item.invoice_no }}</span>
          金额：{{ item.tax_price_total }} 税额：{{ item.tax_total }}
        </div>
      </div>
    </s-table>
  </a-card>
</template>

<script>
import debounce from 'lodash/debounce';
import { getCustomer } from '@/api/common';
import { STable } from '@/components';
import { getWmsBalancePage } from '@/api/wms'
import { getEInvoiceAndSendEmail } from '@/api/fms'

export default {
  name: 'WmsBalance',
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {
        order_type: 1
      },
      buyerOps: [],
      customerInfo: undefined,
      fetching: false,
      chargeType: {
        1: { value: 1, name: '现金' },
        2: { value: 2, name: '月结' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '序列号',
          dataIndex: 'serial_num'
        },
        {
          title: '开票状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '业务编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '业务类型',
          dataIndex: 'order_type',
          customRender: (text) => {
            switch (text) {
              case 1:
                return '查验';
              case 2:
                return '暂落箱';
              case 3:
                return '仓库租赁';
              case 4:
                return '仓储行政';
            }
          }
        },
        {
          title: '税率',
          dataIndex: 'tax_rate_info'
        },
        // {
        //   title: '发票抬头',
        //   dataIndex: 'invoice_info',
        //   scopedSlots: { customRender: 'invoice_info' }
        // },
        {
          title: '结算方式',
          dataIndex: 'charge_type',
          customRender: text => {
            return text && text === 1 ? '现金' : text === 2 ? '月结' : '现金、月结'
          }
        },
        {
          title: '费用明细',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfoList' }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark',
          width: 200
        },
        {
          title: '开票金额',
          dataIndex: 'sum_amount'
        },
        {
          title: '已认领金额',
          dataIndex: 'claim_amount'
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_record_list',
          scopedSlots: { customRender: 'invoiceRecordList' }
        }
      ],
      loadData: parameter => {
        return getWmsBalancePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res;
        });
      }
    }
  },
  created() {},
  methods: {
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.queryParam['invoice_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['invoice_start_time'] = null
        this.queryParam['invoice_end_time'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res;
        this.fetching = false;
      });
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.queryParam['customer_id'] = null;
      } else {
        this.queryParam['customer_id'] = value.key;
      }
      this.customerInfo = value;
      this.buyerOps = [];
      this.fetching = false;
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['start_date'] = startDate;
        this.queryParam['end_date'] = endDate;
      } else {
        this.queryParam['start_date'] = null;
        this.queryParam['end_date'] = null;
      }
    },
    handleBizTypeChange(a) {
      this.$refs.table.refresh(true)
    },
    downloadInvoiceFile(item) {
      getEInvoiceAndSendEmail({ 'invoice_no': item.invoice_no, 'is_business': '1' }).then(v => {
        item.is_download = '1';
        if (v) {
          window.open(v, true);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
